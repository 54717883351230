/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import { useTranslation } from "react-i18next";

import rSubscriptionIcon from "@/assets/rsubscription.png";

import "./topicInfo.css";
import { Link } from "react-router-dom";
import { selectAlertWindow, toogleAlert } from "../address/addressSlice";
import { GetCoins } from "../alert/alertSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import Alert from "@/features/alert/alert";
import { Switch, message } from "antd";
import {
  getOmData,
  getCustomerList,
  addHashToCustomer,
  deleteHashFromCustomer,
} from "@/features/address/om";
import { useEffect, useState } from "react";
export interface InfoProps {
  title: string;
  icon: string;
  name: string;
  belongTo?: string;
  addressCount?: number;
  tagList?: Array<string>;
  details?: string;
  blockDetail?: string;
  firstActive?: string;
  lastActive?: string;
  useSubscription?: boolean;
  subscription?: boolean;
  blockchain?: string;
  type?: string;
  entityId?: number;
  referenceAddress?: string;
}

export default function TopicInfo(prop: InfoProps) {
  const { t } = useTranslation();
  const showAlert = useAppSelector(selectAlertWindow);
  const [isInList, setIsInList] = useState(false);
  const [hashData, setHashData] = useState<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    if (prop.type === "1") {
      getData();
    }
  }, [prop.type]);
  const getData = async () => {
    let res = await getOmData(prop.blockchain, prop.name);
    setHashData(res);
    let lists = res?.lists;
    let isIn = false;
    lists.map((item: { name: string }) => {
      if (item.name === "My list") {
        isIn = true;
      }
    });
    setIsInList(isIn);
  };

  const onChange = (checked: boolean) => {
    if (checked) {
      // 添加
      addHashToList();
    } else {
      // 删除
      removeHashFromList();
    }
  };
  const addHashToList = async () => {
    let res = await getCustomerList();
    let list = res?.items || [];
    let isHaveMylist = false;
    let MylistData: any = null;
    list.map((item: { name: string }) => {
      if (item.name === "My list") {
        isHaveMylist = true;
        MylistData = item;
      }
    });
    if (!isHaveMylist) {
      messageApi.open({
        type: "error",
        content:
          "There is no configuration list here, please contact the administrator。",
      });
    }
    let addRes = await addHashToCustomer(MylistData.id, {
      blockchain: prop.blockchain,
      hash: prop.name,
    });
    getData();
  };
  const removeHashFromList = async () => {
    let list = hashData?.lists || [];
    let MylistData: any = null;
    list.map((item: { name: string }) => {
      if (item.name === "My list") {
        MylistData = item;
      }
    });
    let res = await deleteHashFromCustomer(
      MylistData?.id,
      MylistData?.addressId
    );
    getData();
  };
  return (
    <>
      {contextHolder}
      <div className="topic-info">
        <div className="left">
          <p className="title">{prop.title}</p>
          <div className="info-body">
            <img
              className="body-img"
              src={prop.icon}
              alt=""
              style={{ visibility: prop.icon ? "visible" : "hidden" }}
            />
            <div className="info">
              <p className="name">{prop.name}</p>
              {prop.belongTo || prop.tagList ? (
                <div className="belong-info">
                  {prop.belongTo ? (
                    <p className="belong-text">
                      Belongs to&nbsp;
                      {prop.entityId ? (
                        <Link
                          to={`/entities/${prop.belongTo}/${prop.entityId}`}
                        >
                          <span>{prop.belongTo}</span>
                        </Link>
                      ) : (
                        // 去除跳转钱包 <Link to={`/address/2/${prop.blockchain}/${prop.referenceAddress}`}><span>{prop.belongTo}</span></Link>
                        <span>{prop.belongTo}</span>
                      )}
                    </p>
                  ) : null}
                  {prop.tagList ? (
                    <div className="tags">
                      {prop.tagList.map((i, k) => (
                        <span key={"tag-" + k}>{i}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
              ) : null}
              {prop.details ? (
                <p className="detail-info">{prop.details}</p>
              ) : null}
              {prop.blockDetail ? (
                <p className="detail-info">{prop.blockDetail}</p>
              ) : null}
              {prop.addressCount ? (
                <p className="address-count">
                  Contains {Number(prop.addressCount).toLocaleString()}{" "}
                  Addresses
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="right">
          {/* {prop.type === "1" && (
            <div className="topic-info-switch">
              <span style={{ marginRight: "10px" }}>Ongoing Monitoring</span>
              <Switch checked={isInList} onChange={onChange} />
            </div>
          )} */}

          {prop.firstActive ? (
            <div className="active-time">
              <p>First Activity&nbsp;{prop.firstActive}</p>
              <p>Last Activity&nbsp;{prop.lastActive}</p>
            </div>
          ) : null}
        </div>
      </div>

      {showAlert ? <Alert /> : null}
    </>
  );
}
