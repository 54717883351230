import Header from "@/features/user/Header";
import Navigation from "@/features/common/navigation";

import Topic from "@/features/common/topicInfo";
import RiskScore from "@/features/address/riskScore";
import AssetsList from "@/features/address/assetsList";
import NumberDeals from "@/features/address/numberDeals";
import AccountInfo from "@/features/address/accountInfo";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  initAddress,
  selectAddressInfo,
  selectLoading,
  selectAnalysisType,
  GetAnalysisData,
  showAssets,
  AddressType,
  selectChoosMetadata,
  changeChoosMetadata,
  selectSymbol,
  selectBlockChain,
  blockchainTranslate,
} from "@/features/address/addressSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { AnalysisType, ObjectType } from "@/features/address/addressAPI";
import { useEffect, useState } from "react";
import AddressList from "@/features/address/addressList";

export default function AddressPage() {
  const { id, blockchain, type } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoading);
  const addressInfo = useAppSelector(selectAddressInfo);
  const analysisType = useAppSelector(selectAnalysisType);
  const metadata = useAppSelector(selectChoosMetadata);
  const symbolText = useAppSelector(selectSymbol);
  const blockchains = useAppSelector(selectBlockChain);

  // 如果有资产，默认为ALL，否则为MAIN
  const [coin, setCoin] = useState("");

  useEffect(() => {
    if (!loading && id && blockchain && type) {
      dispatch(
        initAddress({
          hash: id,
          blockchain,
          analysisType,
          wallet: parseInt(type) === 2,
        })
      );
    }
  });

  if (!id || !blockchain || !type) return navigate("/");

  // 初始化coins
  if (coin === "") setCoin(showAssets(blockchain) ? "ALL" : "MAIN");

  document.title = `${type === "1" ? "Address" : "Wallet"} ${id}`;

  const changeAnalyType = (analyType: AnalysisType) => {
    dispatch(
      GetAnalysisData({
        analysisType: analyType,
        objectType: type === "2" ? ObjectType.Wallet : ObjectType.Address,
        objectId: id,
        blockchain: blockchain,
        coin,
      })
    );
  };

  const ChangeRiskSelect = (coinValue: string, index: number) => {
    setCoin(coinValue);
    dispatch(
      GetAnalysisData({
        analysisType: analysisType,
        objectType: type === "2" ? ObjectType.Wallet : ObjectType.Address,
        objectId: id,
        blockchain: blockchain,
        coin: coinValue,
      })
    );
    dispatch(changeChoosMetadata({ blockchain, index }));
  };
  return (
    <>
      <Header />
      <div className="body-container">
        <Navigation id={id} />

        <Topic
          title={
            blockchainTranslate(blockchain) +
            (type === "1"
              ? " " + t("address.info.title")
              : " " + t("address.info.wallet"))
          }
          icon={
            blockchains
              ? `https://app.scorechain.com/images/cryptoicons/${blockchains[blockchain].icon}`
              : ""
          }
          name={
            type === "1"
              ? id
              : `“${addressInfo?.entityName || ""}” - ${
                  blockchainTranslate(blockchain) + " " +t("entities.wallet")
                }`
          }
          belongTo={addressInfo?.entityName}
          tagList={addressInfo?.tags}
          details={addressInfo?.source}
          addressCount={addressInfo?.numberOfAddresses}
          useSubscription={true}
          subscription={true}
          firstActive={addressInfo?.firstActivityDate}
          lastActive={addressInfo?.lastActivityDate}
          entityId={addressInfo?.entityId}
          blockchain={blockchain}
          referenceAddress={addressInfo?.referenceAddress}
          type={type}
        />
        <RiskScore
          symbol={symbolText || ""}
          showAssets={showAssets(blockchain)}
          onChangeType={changeAnalyType}
          onChangeSelect={ChangeRiskSelect}
        />
        {metadata ? <AccountInfo {...metadata} /> : null}
        {/* 非比特币显示资产 */}
        {showAssets(blockchain) ? <AssetsList /> : null}

        <NumberDeals />

        {type && parseInt(type) === AddressType.Wallet ? <AddressList /> : null}
      </div>
    </>
  );
}
